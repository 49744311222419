<script setup>
import ButtonLink from '@/Components/Button/Link.vue';
import Footer from '@/Components/Footer/FooterLogin.vue';
import GuestLayout from '@/Layouts/GuestLayout.vue';

import { useForm } from '@inertiajs/vue3';
import { inject } from 'vue';
const route = inject('route');
const props = defineProps({
    recovery_codes: Array,
    next: String,
});

const form = useForm({
    code: '',
});

const sendForm = () => {
    form.put(route('location.two-factor.update'), {
        preserveState: true,
        preserveScroll: true,
        onError: (errors) => (form.errors = mapErrors(errors)),
    });
};

const printCodes = () => {
    window.print();
};
</script>

<template>
    <GuestLayout title="Backup codes">
        <div class="container relative flex flex-col items-center w-full -top-50">
            <div class="w-full p-8 bg-white rounded-2xl max-w-240 text-blue">
                <h1 class="text-[32px] font-bold mb-2 leading-9">
                    {{ $t('Two Factor Authentication - Backup codes') }}
                </h1>

                <h2 class="mb-3 text-lg font-bold text-pink">
                    {{ $t('2FA successfully set for your account!') }}
                </h2>

                <p class="mb-6 text-base print:hidden">
                    {{ $t('Download backup codes and continue.') }}
                </p>

                <h2 class="mb-3 text-lg font-bold">
                    {{ $t('Backup codes') }}
                </h2>

                <p class="mb-6 text-base">
                    {{ $t('Save these codes on a secure place if you can’t get access with your Authenticator app.') }}
                </p>

                <div v-if="recovery_codes" class="text-base">
                    <ul v-for="(code, i) in recovery_codes" :key="i">
                        <li>{{ code }}</li>
                    </ul>
                </div>

                <div class="flex gap-8 mt-6 text-base font-bold print:hidden">
                    <button @click="printCodes()">{{ $t('Print') }}</button>
                </div>

                <ButtonLink pink :href="next" class="mt-4 md:float-right w-fit print:hidden">{{
                    $t('I Have Saved the backup codes')
                }}</ButtonLink>
            </div>
            <Footer />
        </div>
    </GuestLayout>
</template>
